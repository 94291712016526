<template>
  <div class="detailPage">
    <div class="detailContent">
        <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="100px">
          <el-form-item label="科室名称" prop="name">
            <el-input v-model="dataForm.name" placeholder="科室名称"></el-input>
          </el-form-item>
          <el-form-item label="科室首字母" prop="firstWord">
            <el-input v-model="dataForm.firstWord" placeholder="科室首字母"></el-input>
          </el-form-item>
          <el-form-item label="简介" prop="description">
            <el-input v-model="dataForm.description" type="textarea" placeholder="简介"></el-input>
          </el-form-item>
			<div style="margin-bottom: 20px;">
				<el-transfer
					style="text-align: left; display: inline-block"
					v-model="checkedIds"
					filterable
					:titles="['疾病', '已选疾病']"
					:button-texts="['到左边', '到右边']"
					:format="{
						noChecked: '${total}',
						hasChecked: '${checked}/${total}'
					}"
					@change="handleChange"
					:data="diseases"
				>
					<!-- <el-button class="transfer-footer" slot="left-footer" size="small">操作</el-button>
					<el-button class="transfer-footer" slot="right-footer" size="small">操作</el-button> -->
				</el-transfer>
			</div>
          <el-form-item class="buttons">
            <el-button @click="back()">取消</el-button>
            <el-button type="primary" @click="dataFormSubmit()">提交</el-button>
          </el-form-item>
        </el-form>
      </div>
  </div>
</template>

<script>
export default {
	name: 'deptOption',
	data () {
		return {
			dataForm: {
				id: 0,
				name: '',
				firstWord: '',
				description: ''
			},
			dataRule: {
				name: [
					{ required: true, message: '科室名称不能为空', trigger: 'blur' }
				],
				firstWord: [
					{ required: true, message: '首字母不能为空', trigger: 'blur' }
				]
			},
			value: [],
			diseases: [],
			checkedIds: [],
			dhdRecommendIds: [] // 此前添加过的科室疾病 记录
		};
	},
	beforeCreate () {
		if (this.$route.query.id) {
			this.$route.meta.navName = '修改科室';
		} else {
			this.$route.meta.navName = '新增科室';
		}
	},
	created () {
		const id = this.$route.query.id;
		if (id) {
			this.dataForm.id = id;
			this.getDetail(id);
		}
		this.getDiseases();
	},
	methods: {
		back () {
			this.$router.go(-1);
		},
		handleChange () {

		},
		getDiseases () {
			this.$root.request('diseaseList', {
				keywords: '',
				page: 1,
				limit: 3000
			}).then((data) => {
				if (data) {
					this.diseases = data.rows.map((d, ind) => {
						return {
							key: d.id,
							label: d.name
						};
					});
				}
			});
		},
		getDetail (id) {
			this.$root.request('deptDetail', { id }).then((data) => {
				if (data) {
					this.dataForm = {
						id: this.$route.query.id,
						name: data.name,
						firstWord: data.firstWord,
						description: data.description
					};
					this.checkedIds = data.diseaseIds;
					this.dhdRecommendIds = data.dhdRecommendIds; // 此前添加过的科室疾病 记录
				}
			});
		},
		// 表单提交
		dataFormSubmit () {
			this.$refs.dataForm.validate((valid) => {
				if (valid) {
					this.$root.request('deptAddOrUpdate', {
						...this.dataForm,
						dhdRecommendIds: this.dhdRecommendIds,
						diseaseIds: this.checkedIds
					}).then((data) => {
						if (data) {
							this.$root.elMsg('操作成功', 'success', () => {
								if (this.$route.query.id) {
									this.back();
								} else {
									this.$refs.dataForm.resetFields();
								}
							}, 1000);
						}
					}).catch(e => {
						this.$root.elMsg('操作失败', 'error');
					});
				}
			});
		}
	}
};
</script>
<style lang="less" scoped>
	/deep/ .el-input,  .el-textarea {
		width: auto !important;
	}
</style>
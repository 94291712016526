var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "detailPage" }, [
    _c(
      "div",
      { staticClass: "detailContent" },
      [
        _c(
          "el-form",
          {
            ref: "dataForm",
            attrs: {
              model: _vm.dataForm,
              rules: _vm.dataRule,
              "label-width": "100px"
            },
            nativeOn: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.dataFormSubmit()
              }
            }
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "科室名称", prop: "name" } },
              [
                _c("el-input", {
                  attrs: { placeholder: "科室名称" },
                  model: {
                    value: _vm.dataForm.name,
                    callback: function($$v) {
                      _vm.$set(_vm.dataForm, "name", $$v)
                    },
                    expression: "dataForm.name"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "科室首字母", prop: "firstWord" } },
              [
                _c("el-input", {
                  attrs: { placeholder: "科室首字母" },
                  model: {
                    value: _vm.dataForm.firstWord,
                    callback: function($$v) {
                      _vm.$set(_vm.dataForm, "firstWord", $$v)
                    },
                    expression: "dataForm.firstWord"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "简介", prop: "description" } },
              [
                _c("el-input", {
                  attrs: { type: "textarea", placeholder: "简介" },
                  model: {
                    value: _vm.dataForm.description,
                    callback: function($$v) {
                      _vm.$set(_vm.dataForm, "description", $$v)
                    },
                    expression: "dataForm.description"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticStyle: { "margin-bottom": "20px" } },
              [
                _c("el-transfer", {
                  staticStyle: {
                    "text-align": "left",
                    display: "inline-block"
                  },
                  attrs: {
                    filterable: "",
                    titles: ["疾病", "已选疾病"],
                    "button-texts": ["到左边", "到右边"],
                    format: {
                      noChecked: "${total}",
                      hasChecked: "${checked}/${total}"
                    },
                    data: _vm.diseases
                  },
                  on: { change: _vm.handleChange },
                  model: {
                    value: _vm.checkedIds,
                    callback: function($$v) {
                      _vm.checkedIds = $$v
                    },
                    expression: "checkedIds"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { staticClass: "buttons" },
              [
                _c(
                  "el-button",
                  {
                    on: {
                      click: function($event) {
                        return _vm.back()
                      }
                    }
                  },
                  [_vm._v("取消")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function($event) {
                        return _vm.dataFormSubmit()
                      }
                    }
                  },
                  [_vm._v("提交")]
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }